<template>
  <div :class="{ 'meetsWindowActive':meetsWindow }">
<!--    <span @click="createRoom">Создать</span>-->
    <div
      class="jitsi-wrapper"
      v-show="meetsWindow"
      ref="jitsiWrapper"
    >
    </div>
    <div
      class="wrapper-rooms"
      v-if="availableRooms && !dataLoading"
    >
      <v-btn
        v-if="availableRooms.length"
        color="#48C666"
        class="button-refresh"
        :loading="refreshLoading"
        @click="getInfoActiveRooms"
      >
        Обновить все
      </v-btn>
      <div
        v-for="item in availableRooms"
        :key="item.id"
        class="room"
      >
        <p
          class="room-title"
          v-text="item.title"
        />
        <span
          v-if="item.participants"
          class="room-info-quantity"
        >Участников: {{ item.participants }}</span>
        <span
          v-if="item.created_time"
          class="room-info-time"
        > {{ item.created_time }}</span>
        <button
          class="room-button-join"
          :class="[{ 'active-button-connect': item.active },{ 'active-button-connect--disabled' : isUserVDI }]"
          @click="connectRoom(item.roomurl)"
        >
          <svg class="room-button-join__img" />
          <span v-if="item.active">Присоединиться к звонку</span>
          <span v-else>Собрание не началось</span>
        </button>
      </div>
    </div>
    <v-alert
      v-if="isUserVDI"
      class="fixed-alert"
      prominent
      type="warning"
    >
      <v-row align="center">
        <v-col class="grow">
          Доступ к Собраниям ограничен с удаленного рабочего стола! Сверните удаленный рабочий стол, и войдите на Портал через браузер рабочего компьютера или тонкого клиента
        </v-col>
      </v-row>
    </v-alert>
    <data-loading :color-circular="'#48C666'" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import dataLoading from './components/dataLoading.vue'
  export default {
    name: 'Meetings',
    components: { dataLoading },
    data () {
      return {
        itemWikiS: '',
        scriptReady: false,
        meetsWindow: '',
        availableRooms: [],
        activeRooms: [],
        refreshLoading: true,
        isUserVDI: false,
      }
    },
    computed: {
      ...mapGetters(['foremanFIO', 'jitsiScriptLoaded']),
      dataLoading () {
        return this.$store.getters.loading
      },
    },
    async mounted () {
      this.addScriptJitsi()
      await this.getRoomsList()
      await this.getInfoActiveRooms()
      this.refreshRoomInformation()
      this.refreshRoomStartTimer()
      this.calculateStartTime()
      this.checkIp()
    },
    methods: {
      checkIp () {
        this.$store.dispatch('getUserIp').then((res) => {
          const curBeginningIp = res.split('.').slice(0, 2).join('.')
          if (curBeginningIp === '172.20' || curBeginningIp === '172.30') {
            this.isUserVDI = true
          }
        }).catch((e) => {
          console.log('Error:', e)
        })
      },
      addScriptJitsi () {
        if (this.jitsiScriptLoaded) {
          this.scriptReady = true
          return
        }
        const script = document.createElement('script')
        script.setAttribute('type', 'text/javascript')
        script.setAttribute('src', 'https://conf.itmuse.ru/external_api.js')
        script.setAttribute('async', '')

        document.body.appendChild(script)
        this.$store.dispatch('setJitsiScriptLoaded', true)
        script.onload = () => {
          this.scriptReady = true
        }
      },
      async getRoomsList () {
        this.$store.dispatch('setDataLoading', true)
        await this.$store.dispatch('getRoomsList').then((res) => {
          this.availableRooms = res
        }).catch((e) => {
          console.log('Error:', e)
        })
        this.$store.dispatch('setDataLoading', false)
      },
      closeJitsi () {
        this.meetsWindow.dispose()
        this.meetsWindow = ''
        this.$store.dispatch('setConferenceIsActive', false)
      },
      async connectRoom (url) {
        if (this.isUserVDI) {
          return
        }
        const nameRoom = url.split('/')[url.split('/').length - 1]
        const domain = 'conf.itmuse.ru'
        const options = {
          roomName: nameRoom,
          width: '100%',
          height: '100%',
          parentNode: this.$refs.jitsiWrapper,
          lang: 'ru',
          userInfo: {
            email: '',
            displayName: this.foremanFIO.fiochat,
          },
        }
        if (this.scriptReady && !this.meetsWindow) {
          this.meetsWindow = new window.JitsiMeetExternalAPI(domain, options)
          this.$store.dispatch('setConferenceIsActive', true)
          this.meetsWindow.addListener('videoConferenceLeft', () => {
            this.closeJitsi()
          })
        }
      },
      createRoom () {
        const domain = 'conf.itmuse.ru'
        const options = {
          width: '100%',
          height: '100%',
          parentNode: this.$refs.jitsiWrapper,
          lang: 'ru',
          userInfo: {
            email: '',
            displayName: this.foremanFIO.fiochat,
          },
        }
        if (this.scriptReady && !this.meetsWindow) {
          this.meetsWindow = new window.JitsiMeetExternalAPI(domain, options)
        }
      },
      async getInfoActiveRooms () {
        this.refreshLoading = true
        await this.$store.dispatch('getInfoActiveRooms').then((res) => {
          this.activeRooms = res.room_census
        })
        for (const room in this.availableRooms) {
          const roomName = this.availableRooms[room].roomurl.split('/')[this.availableRooms[room].roomurl.split('/').length - 1]
          delete this.availableRooms[room].active
          delete this.availableRooms[room].participants
          delete this.availableRooms[room].created_time
          for (const activeRoom in this.activeRooms) {
            const roomActiveName = this.activeRooms[activeRoom].room_name.split('@')[0]
            if (roomName.toLowerCase() === roomActiveName.toLowerCase()) {
              this.$set(this.availableRooms[room], 'active', 'true')
              this.$set(this.availableRooms[room], 'participants', this.activeRooms[activeRoom].participants)
              this.$set(this.availableRooms[room], 'created_time', this.calculateStartTime(this.activeRooms[activeRoom].created_time))
            }
          }
        }
        this.refreshLoading = false
      },
      refreshRoomInformation () {
        this.refreshTimeout = setInterval(async () => {
          await this.getInfoActiveRooms()
        }, 10000)
      },
      refreshRoomStartTimer () {
        this.refreshRoomStartTimeout = setInterval(async () => {
          if (this.availableRooms) {
            this.availableRooms.forEach((item) => {
              if (item.created_time) {
                item.created_time = this.secondsToTime(this.timeToSeconds(item.created_time) + 1)
              }
            })
          }
        }, 1000)
      },
      timeToSeconds (timeString) {
        if (timeString.split(':').length === 3) {
          const [hours, minutes, seconds] = timeString.split(':').map(Number)
          return hours * 3600 + minutes * 60 + seconds
        } else {
          const [minutes, seconds] = timeString.split(':').map(Number)
          return minutes * 60 + seconds
        }
      },
      secondsToTime (seconds) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = seconds % 60
        const formattedHours = String(hours).padStart(2, '0')
        const formattedMinutes = String(minutes).padStart(2, '0')
        const formattedSeconds = String(remainingSeconds).padStart(2, '0')
        if (formattedHours === '00') {
          return `${formattedMinutes}:${formattedSeconds}`
        }
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      },
      calculateStartTime (timeInMilliseconds) {
        const currentTimestamp = Date.now()
        const timeDifference = currentTimestamp - timeInMilliseconds
        const seconds = Math.floor(timeDifference / 1000) % 60
        const minutes = Math.floor(timeDifference / 1000 / 60) % 60
        const hours = Math.floor(timeDifference / 1000 / 60 / 60)
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
        const formattedHours = hours < 10 ? `0${hours}` : hours
        if (formattedHours === '00') {
          return `${formattedMinutes}:${formattedSeconds}`
        }
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      },
    },
    beforeDestroy () {
      this.$store.dispatch('setConferenceIsActive', false)
      clearTimeout(this.refreshTimeout)
      clearTimeout(this.refreshRoomStartTimeout)
    },
  }
</script>
<style lang="sass">
.meetsWindowActive
  overflow: hidden
.button-refresh
  display: flex
  align-items: center
  position: absolute
  right: calc(50% - 28px)
  transform: translate(50%, 0)
  top: 27px
  justify-content: center
.jitsi-wrapper
  position: fixed
  top: 0
  right: 0
  left: 56px
  bottom: 0
  z-index: 2

.fixed-alert
  position: fixed
  top: 20px
  left: 116px
  right: 60px
  z-index: 2

  .grow
    font-size: 24px

.wrapper-rooms
  padding: 100px 80px 30px
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: 20px
  .room
    width: 100%
    height: 288px
    background: linear-gradient(45deg, rgb(84, 88, 177) 17%, rgb(87, 92, 197) 88%)
    border-radius: 5px
    display: flex
    flex-direction: column
    justify-content: space-between
    &:nth-child(2n)
      background: linear-gradient(-45deg, rgb(70, 85, 197) 30%, rgb(82, 90, 190) 80%)
    &:nth-child(4n)
      background: linear-gradient(45deg, rgb(50, 68, 177) 17%, rgb(87, 92, 167) 88%)
    .room-title
      margin: 36px 15px 0
      text-align: center
      color: white
      font-weight: 700
      font-size: 35px
      line-height: 41px
    .room-info-quantity
      font-weight: 700
      font-size: 28px
      line-height: 41px
      color: white
      margin: 0 auto
      display: flex
      width: fit-content
      text-align: center
    .room-info-time
      font-size: 20px
      line-height: 30px
      color: white
      margin: 0 auto
      display: flex
      width: fit-content
      text-align: center
    .room-button-join
      background-color: #b6b2b2
      border-radius: 50px
      color: white
      padding: 14px 43px
      cursor: pointer
      display: flex
      align-items: center
      gap: 18px
      margin: 0 auto 36px
      font-size: 16px
      line-height: 23px
      position: relative
      .room-button-join__img
        width: 18px
        height: 18px
        border: none
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.62 7.79C5.06 10.62 7.38 12.94 10.21 14.38L12.41 12.18C12.69 11.9 13.08 11.82 13.43 11.93C14.55 12.3 15.75 12.5 17 12.5C17.2652 12.5 17.5196 12.6054 17.7071 12.7929C17.8946 12.9804 18 13.2348 18 13.5V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18C12.4913 18 8.1673 16.2089 4.97918 13.0208C1.79107 9.8327 0 5.50868 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H4.5C4.76522 0 5.01957 0.105357 5.20711 0.292893C5.39464 0.48043 5.5 0.734784 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z' fill='white'/%3E%3C/svg%3E%0A")
    .active-button-connect
      background: #48C666
      &:after
        content: ''
        position: absolute
        border: 10px solid #48C666
        left: -10px
        right: -10px
        top: -3px
        bottom: -3px
        border-radius: 50px
        animation: pulse 1.8s linear infinite
        opacity: 0.5
      @keyframes pulse
        0%
          transform: scale(.9)
        70%
          transform: scale(1)
          box-shadow: 0 0 0 50px rgba(#5a99d4, 0)
        100%
          transform: scale(.9)
          box-shadow: 0 0 0 0 rgba(#5a99d4, 0)
    .active-button-connect--disabled
      opacity: 0.5
      pointer-events: none
</style>
